.price-component-container {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

@media(max-width: 479px) {
  .price-component-container {
    width: 242px;
    height: 188px;
    flex-direction: column;
  }
}
