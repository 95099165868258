.stats5-max-width {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.stats5-container2 {
  gap: 32px;
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.stats5-container3 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.stats5-container4 {
  flex: 1;
  display: flex;
  padding: 32px;
  align-items: center;
  flex-direction: column;
}
.stats5-container5 {
  flex: 1;
  display: flex;
  padding: 32px;
  align-items: center;
  flex-direction: column;
}
.stats5-text5 {
  display: inline-block;
}
.stats5-text6 {
  display: inline-block;
  text-align: center;
}
.stats5-text7 {
  display: inline-block;
}
.stats5-text8 {
  display: inline-block;
  text-align: center;
}
@media(max-width: 767px) {
  .stats5-container4 {
    align-self: flex-start;
  }
  .stats5-container5 {
    align-self: flex-start;
  }
  .stats5-text5 {
    align-self: center;
  }
  .stats5-text7 {
    align-self: center;
  }
}
