.home-container10 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-header-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-header {
  flex: 0 0 auto;
  width: 1150px;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  position: relative;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-container11 {
  flex: 0 0 auto;
  width: auto;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.home-container12 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  flex-direction: column;
}
.home-container13 {
  display: flex;
  align-self: center;
  align-items: space-between;
  flex-direction: column;
}
.home-container14 {
  width: 101px;
  display: flex;
  align-self: center;
  align-items: space-between;
  flex-direction: column;
}
.home-image10 {
  width: 68px;
  height: 66px;
  align-self: center;
  object-fit: cover;
}
.home-container15 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
.home-container16 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
.home-container17 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
.home-container18 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
.home-text10 {
  width: 171px;
  height: 69px;
  font-size: 40px;
  text-align: center;
  font-family: Nanum Pen Script;
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.home-container19 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  z-index: 101;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: space-between;
}
.home-logo {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-image11 {
  width: 100px;
  object-fit: cover;
}
.home-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-icon10 {
  width: 24px;
  height: 24px;
}
.home-mid {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text13 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text13:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text14 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text14:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text15 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text15:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text16 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text16:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text17 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text17:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text18 {
  transition: 0.3s;
}
.home-text18:hover {
  color: var(--dl-color-turquoise-default);
}
.home-bot {
  flex: 0 0 auto;
  display: flex;
  margin-top: auto;
  align-items: flex-start;
  flex-direction: column;
}
.home-container20 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-link10 {
  display: contents;
}
.home-container21 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon12 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon12:hover {
  fill: var(--dl-color-purple-default);
}
.home-link11 {
  display: contents;
}
.home-container22 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon14 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon14:hover {
  fill: var(--dl-color-pink-default);
}
.home-link12 {
  display: contents;
}
.home-container23 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon16 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon16:hover {
  fill: var(--dl-color-turquoise-default);
}
.home-link13 {
  display: contents;
}
.home-container24 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon18 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon18:hover {
  fill: var(--dl-color-orange-default);
}
.home-text19 {
  max-width: 500px;
  text-align: center;
}
.home-main {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.home-blur-background {
  top: 0px;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: auto;
  filter: blur(60px);
  height: 70vh;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  background-color: #f2f2f2;
}
.home-hero {
  flex: 0 0 auto;
  width: 100%;
  height: 80vh;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-container25 {
  flex: 0 0 auto;
  width: auto;
  height: 384px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text22 {
  width: 506px;
  height: 241px;
  font-size: 72px;
  text-align: left;
  font-family: Inter;
  font-weight: 800;
  margin-bottom: 46px;
  padding-bottom: 18px;
  text-transform: none;
  text-decoration: none;
}
.home-text27 {
  max-width: 500px;
  text-align: center;
}
.home-link14 {
  display: contents;
}
.home-component12 {
  text-decoration: none;
}
.home-image12 {
  width: 410px;
  height: 480px;
  object-fit: cover;
}
.home-turquoise-cirble {
  top: 439px;
  left: auto;
  right: 430px;
  width: 170px;
  bottom: auto;
  filter: blur(60px);
  opacity: 0.6;
  position: absolute;
  object-fit: cover;
}
.home-purple-circle {
  top: -100px;
  left: auto;
  right: -26px;
  width: 400px;
  bottom: auto;
  filter: blur(60px);
  opacity: 0.4;
  position: absolute;
  object-fit: cover;
}
.home-left {
  top: 653px;
  left: 0px;
  right: auto;
  width: 420px;
  bottom: auto;
  position: absolute;
  object-fit: cover;
}
.home-right {
  top: 441px;
  left: auto;
  right: 0px;
  width: 612px;
  bottom: auto;
  position: absolute;
  object-fit: cover;
}
.home-clients {
  flex: 0 0 auto;
  width: 851px;
  display: flex;
  z-index: 100;
  overflow: hidden;
  position: relative;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.home-divider1 {
  top: 0px;
  left: auto;
  right: auto;
  width: 100%;
  bottom: auto;
  height: 1px;
  position: absolute;
  background: linear-gradient(90deg, rgba(192, 204, 218, 0.1) 0%, rgba(192, 204, 218, 0.6) 50.38%, rgba(192, 204, 218, 0.1) 100%);;
}
.home-link15 {
  display: contents;
}
.home-image13 {
  width: 52px;
  height: 52px;
  object-fit: cover;
  text-decoration: none;
}
.home-link16 {
  display: contents;
}
.home-image14 {
  width: 52px;
  height: 52px;
  object-fit: cover;
  text-decoration: none;
}
.home-link17 {
  display: contents;
}
.home-image15 {
  width: 52px;
  height: 52px;
  object-fit: cover;
  text-decoration: none;
}
.home-divider2 {
  top: auto;
  left: auto;
  right: auto;
  width: 100%;
  bottom: 0px;
  height: 1px;
  position: absolute;
  background: linear-gradient(90deg, rgba(192, 204, 218, 0.1) 0%, rgba(192, 204, 218, 0.6) 50.38%, rgba(192, 204, 218, 0.1) 100%);;
}
.home-text28 {
  display: inline-block;
}
.home-text29 {
  display: inline-block;
}
.home-text30 {
  display: inline-block;
  text-align: center;
}
.home-text31 {
  display: inline-block;
  text-align: center;
}
.home-features1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text33 {
  max-width: 500px;
  text-align: center;
}
.home-features2 {
  display: flex;
  flex-wrap: wrap;
  margin-top: 49px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-container26 {
  flex: 0 0 auto;
  width: 320px;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-link18 {
  display: contents;
}
.home-component15 {
  text-decoration: none;
}
.home-container27 {
  flex: 0 0 auto;
  width: 320px;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-link19 {
  display: contents;
}
.home-component17 {
  text-decoration: none;
}
.home-container28 {
  flex: 0 0 auto;
  width: 320px;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-link20 {
  display: contents;
}
.home-component19 {
  text-decoration: none;
}
.home-container29 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-testimonials {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: center;
  background-color: var(--dl-color-turquoise-100);
}
.home-container30 {
  display: flex;
  flex-wrap: wrap;
  max-width: 1100px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-container31 {
  flex: 0 0 auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.home-image16 {
  top: -22px;
  left: -65px;
  right: auto;
  width: 100px;
  bottom: auto;
  z-index: 10;
  position: absolute;
  object-fit: cover;
}
.home-text34 {
  z-index: 100;
  max-width: 500px;
}
.home-container32 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-container33 {
  flex: 0 0 auto;
  width: auto;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-container34 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-container35 {
  flex: 0 0 auto;
  width: 307px;
  height: 214px;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  box-shadow: 0px 10px 20px 0px rgba(41, 41, 42, 0.07);
  align-items: flex-start;
  margin-right: 8px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: 17px;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-container36 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-image17 {
  top: 81px;
  left: 46px;
  right: auto;
  width: 15px;
  bottom: auto;
  object-fit: cover;
}
.home-container37 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text52 {
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-container38 {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
}
.home-container39 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: center;
}
.home-container40 {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-container41 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-container42 {
  flex: 0 0 auto;
  width: 310px;
  height: 214px;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  box-shadow: 0px 10px 20px 0px rgba(41, 41, 42, 0.07);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: 17px;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-container43 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-image18 {
  top: 81px;
  left: 46px;
  right: auto;
  width: 15px;
  bottom: auto;
  object-fit: cover;
}
.home-container44 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text54 {
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-link21 {
  color: #001ec7;
  text-decoration: underline;
}
.home-link22 {
  color: #001dc3;
  text-decoration: underline;
}
.home-container45 {
  flex: 0 0 auto;
  width: 310px;
  height: 212px;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  box-shadow: 0px 10px 20px 0px rgba(41, 41, 42, 0.07);
  align-items: flex-start;
  margin-left: 8px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-container46 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-image19 {
  top: 81px;
  left: 46px;
  right: auto;
  width: 15px;
  bottom: auto;
  object-fit: cover;
}
.home-container47 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text59 {
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-feature1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-container48 {
  flex: 0 0 auto;
  width: 540px;
  height: 769px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-purple-100);
}
.home-iframe {
  width: 422px;
  height: 774px;
}
.home-container49 {
  flex: 1;
  margin: var(--dl-space-space-doubleunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text62 {
  max-width: 400px;
  margin-top: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-feature2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-container50 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-text70 {
  text-align: center;
}
.home-image20 {
  width: 100%;
  height: 474px;
  margin-top: var(--dl-space-space-tripleunit);
  object-fit: cover;
}
.home-cta {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-turquoise-default);
}
.home-container51 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-container52 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text80 {
  font-size: 25px;
}
.home-text82 {
  max-width: 400px;
  margin-top: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-link23 {
  color: var(--dl-color-gray-white);
  align-self: flex-start;
  transition: 0.3s;
  padding-top: 12px;
  border-width: 0px;
  padding-left: 32px;
  border-radius: var(--dl-radius-radius-radius6);
  padding-right: 32px;
  padding-bottom: 12px;
  text-decoration: none;
  background-color: var(--dl-color-purple-default);
}
.home-link23:hover {
  background-color: var(--dl-color-purple-900);
}
.home-link23:active {
  background-color: var(--dl-color-purple-1000);
}
.home-container53 {
  width: 520px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-image21 {
  top: -100px;
  left: auto;
  right: var(--dl-space-space-doubleunit);
  width: 232px;
  bottom: auto;
  position: absolute;
  object-fit: cover;
}
.home-image22 {
  top: auto;
  left: var(--dl-space-space-doubleunit);
  right: auto;
  width: 232px;
  bottom: -100px;
  position: absolute;
  object-fit: cover;
}
.home-footer {
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.home-container54 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-navlink {
  display: contents;
}
.home-image23 {
  width: 80px;
  height: 80px;
  object-fit: cover;
  text-decoration: none;
}
.home-container55 {
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: flex-start;
}
.home-link24 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-unit);
  text-decoration: underline;
}
.home-link24:hover {
  color: var(--dl-color-turquoise-default);
}
.home-link25 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-unit);
  text-decoration: underline;
}
.home-link25:hover {
  color: var(--dl-color-turquoise-default);
}
.home-link26 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-unit);
  text-decoration: underline;
}
.home-link26:hover {
  color: var(--dl-color-turquoise-default);
}
.home-divider3 {
  width: 100%;
  height: 1px;
  opacity: 0.2;
  background-color: var(--dl-color-gray-white);
}
.home-container56 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-text83 {
  color: var(--dl-color-gray-300);
  margin-top: var(--dl-space-space-unit);
}
.home-container57 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-link27 {
  display: contents;
}
.home-icon20 {
  fill: #D9D9D9;
  width: 35px;
  height: 31px;
  margin-right: 38px;
  text-decoration: none;
}
.home-link28 {
  display: contents;
}
.home-icon22 {
  fill: #D9D9D9;
  width: 35px;
  height: 31px;
  text-decoration: none;
}
@media(max-width: 991px) {
  .home-header {
    width: 100%;
  }
  .home-image10 {
    align-self: center;
  }
  .home-text10 {
    align-self: center;
    text-align: center;
  }
  .home-container19 {
    display: none;
  }
  .home-hero {
    height: auto;
    flex-direction: column;
  }
  .home-container25 {
    align-items: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .home-text22 {
    text-align: center;
  }
  .home-container30 {
    flex-direction: column;
  }
  .home-container35 {
    margin-bottom: 35px;
  }
  .home-container42 {
    margin-bottom: 34px;
  }
  .home-feature1 {
    flex-direction: column;
  }
  .home-container49 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-container50 {
    align-items: flex-start;
    flex-direction: column;
  }
  .home-container53 {
    display: none;
  }
}
@media(max-width: 767px) {
  .home-header {
    width: 100%;
  }
  .home-container12 {
    margin-left: 258px;
  }
  .home-container14 {
    align-self: center;
  }
  .home-image10 {
    align-self: center;
  }
  .home-text22 {
    text-align: center;
  }
  .home-clients {
    justify-content: center;
  }
  .home-image13 {
    margin: var(--dl-space-space-unit);
  }
  .home-image14 {
    margin: var(--dl-space-space-unit);
  }
  .home-image15 {
    margin: var(--dl-space-space-unit);
  }
  .home-text28 {
    align-self: center;
  }
  .home-text29 {
    align-self: center;
  }
  .home-text32 {
    font-size: 40px;
  }
  .home-container31 {
    align-items: center;
  }
  .home-image16 {
    top: -2px;
    left: 21px;
    right: auto;
    width: 50px;
    bottom: auto;
  }
  .home-text34 {
    font-size: 40px;
    text-align: center;
  }
  .home-text35 {
    font-size: 40px;
    text-align: center;
  }
  .home-container35 {
    margin-bottom: 44px;
  }
  .home-container42 {
    margin-bottom: 44px;
  }
  .home-container48 {
    height: 776px;
  }
  .home-text70 {
    font-size: 40px;
  }
  .home-text71 {
    font-size: 40px;
  }
  .home-text75 {
    font-size: 40px;
  }
  .home-text76 {
    font-size: 40px;
  }
  .home-container55 {
    flex-wrap: wrap;
    justify-content: center;
  }
  .home-container56 {
    flex-direction: column;
  }
  .home-text83 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .home-header {
    width: 100%;
  }
  .home-container12 {
    margin-left: 141px;
  }
  .home-container14 {
    align-self: center;
  }
  .home-image10 {
    align-self: center;
  }
  .home-container15 {
    align-items: flex-start;
    flex-direction: row;
  }
  .home-text10 {
    width: 129px;
    height: 36px;
    font-size: 30px;
    text-align: center;
    margin-left: 3px;
    margin-right: 0px;
  }
  .home-container19 {
    display: none;
  }
  .home-bot {
    width: 100%;
  }
  .home-text19 {
    text-align: center;
  }
  .home-text22 {
    font-size: 60px;
    text-align: center;
  }
  .home-text27 {
    text-align: center;
  }
  .home-image12 {
    width: 383px;
    height: 408px;
  }
  .home-text32 {
    text-align: center;
  }
  .home-text33 {
    text-align: center;
  }
  .home-container35 {
    width: 232px;
    height: 259px;
  }
  .home-container36 {
    width: 185px;
    height: 162px;
  }
  .home-container42 {
    width: 221px;
    height: 258px;
    margin-top: 0px;
    margin-bottom: 1px;
  }
  .home-text70 {
    font-size: 40px;
  }
  .home-text75 {
    font-size: 40px;
  }
  .home-text76 {
    font-size: 40px;
  }
  .home-link23 {
    color: var(--dl-color-gray-white);
    background-color: var(--dl-color-purple-default);
  }
  .home-container57 {
    align-self: center;
  }
  .home-icon20 {
    align-self: center;
  }
  .home-icon22 {
    align-self: center;
  }
}
